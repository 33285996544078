import React from "react";
import {
  Grid,
  Button,
  Avatar,
} from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { FILE_URL, ovHrV5Color } from "../../../../../config";
import { MySelectField, MyTextArea } from "../../../../../components";
import { notesStyles } from "../Styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.5rem 1rem !important",
  },
  switch: {
    color: "#B71C1C !important",
  },
  avatar: {
    marginRight: "8px !important",
    float: "left !important",
    textTransform: "uppercase !important",
    height: "30px !important",
    width: "30px !important",
    fontWeight: "800 !important",
    fontSize: "13px !important",
    backgroundColor: ovHrV5Color.main,
    color: "white !important",
  },
  locationField: {
    backgroundColor: "white !important",
  },

  hrNotesDataContainer: {
    paddingTop: "1rem !important",
  },

  hrNotesDataText: {
    textAlign: "left !important", 
    fontWeight: "bold !important"
  },

  hrNotesDataBtn: {
    textAlign: "right !important", 
    cursor: "pointer !important",
  },

  hrNotesDataEditIcon: {
    textAlign: "right", 
    cursor: "pointer"
  },

  hrNotesDataDate: {
    color: "grey !important", 
    paddingLeft: "35px !important", 
    fontSize: "14px !important",
  },

  hrNotesAddNotesBtn: {
    padding: "5px !important", 
    backgroundColor: "#007E63 !important", 
    display: "flex !important", 
    float: "right !important", 
    color: "white !important",
  },

  hrNotesSaveNotesBtn:{
    padding: "5px !important", 
    backgroundColor: "#007E63 !important", 
    display: "flex !important", 
    float: "right !important", 
    color: "white !important",
  },

  hrNotesBtnContainer:{
    paddingTop: "1rem !important", 
  },

  hrNotesTextArea: {
    paddingTop: "1rem !important", 
    marginLeft: "1px !important",
  }
}))

const Notes = ({
  locationsData,
  notesData,
  userNotes,
  handleNotes,
  editNotes,
  addNotes,
  noteEdit,
  deleteNotes,
  modifyNotes,
}) => {
  const classes = useStyles();
 
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid container xs={12} md={12}>
        <Grid item md={6} xs={12}>
          <MySelectField
            className={classes.locationField}
            name="lid"
            product={"HR"}
            onChange={handleNotes}
            value={userNotes.lid}
            placeholder="Please Select Location"
          >
            <option>Select Location</option>
            {locationsData.map((data) => (
              <option key={data.lid} value={data.lid}>
                {data.location_name}
              </option>
            ))}
          </MySelectField>
        </Grid>

        <Grid item md={6} xs={12} className={classes.hrNotesTextArea}>
          <MyTextArea
            name="notes"
            label="Notes"
            onChange={handleNotes}
            value={userNotes.notes}
            type="text"
            placeholder="Enter Notes"
            rows={8}
          />
        </Grid>

        <Grid item md={6} xs={12} className={classes.hrNotesBtnContainer}>
          {noteEdit ? (
            <Button
              className={classes.hrNotesSaveNotesBtn}
              onClick={editNotes}
            >
              Save
            </Button>
          ) : (
            <Button
              className={classes.hrNotesAddNotesBtn}
              onClick={addNotes}
            >
              Add
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container xs={6} md={6}>
        {notesData.length > 0 &&
          notesData.map((data) => (
            <Grid container xs={12} md={12} className={classes.hrNotesDataContainer} key={data._id}>
              <Grid item xs={1} md={1}>
                {data.image ? (
                  <Avatar className={classes.avatar} src={FILE_URL + data.image} />
                ) : (
                  <Avatar className={classes.avatar}>{data.notes.charAt(0)}</Avatar>
                )}
              </Grid>
              <Grid item xs={9} md={9} className={classes.hrNotesDataText}>
                {data.notes}
              </Grid>
              <Grid item xs={1} md={1} className={classes.hrNotesDataBtn}>
                <DeleteIcon onClick={() => deleteNotes(data._id)} />
              </Grid>
              <Grid item xs={1} md={1} className={classes.hrNotesDataEditIcon}>
                <EditIcon onClick={() => modifyNotes(data)} />
              </Grid>
              <Grid item xs={12} md={12} className={classes.hrNotesDataDate}>
                {data.created_at}
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default Notes;
