import axios from "axios";
import { HEADER_TOKEN } from "../../../../../config";

export const editUserServices = {
  fetchData,
  editTeamRoster,
  updateNotifications,
  updateLocationsData,
  addNotes,
  editNotes,
  deleteNotes,
  fetchDataTeamRoaterLocation
};

function fetchData(uid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/${uid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchDataTeamRoaterLocation(uid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/locations/${uid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editTeamRoster(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/${data._id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function updateNotifications(uid, data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/notifications/${uid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function updateLocationsData(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/locations/${data.uid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addNotes(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/notes`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editNotes(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/notes/${data.id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteNotes(id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/notes/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
