import React, { useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  Grid,
  Switch,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { LoadingData, MySelectField } from "../../../../../components";
import { makeStyles } from "@material-ui/core/styles";
import { ovHrV5Color } from "../../../../../config";

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#007E63",
  },
  "& .MuiSwitch-switchBase.Mui-checked:hover": {
    backgroundColor: alpha("#007E63", theme.palette.action.hoverOpacity),
    color: "#007E63",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#007E63",
  },
}));

const useStyles = makeStyles((theme) => ({
  hrLocationContainer: {
    padding: "1rem !important",
  },

  root: {
    color: "#007E63 !important",
    "&.Mui-checked": {
      color: "#007E63 !important",
    },
    "&:hover": {
      color: "#007E63 !important",
    },
  },

  FormControlLabelSecond: {
    padding: "6px !important",
  },

  switch: {
    color: "#007E63 !important",
  },

  locationField: {
    backgroundColor: "white !important",
  },

  hrLocationLabel: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: `${ovHrV5Color.main} !important`,
    marginBottom: "0.5rem !important",
  },
}));

const Locations = ({
  userDetails,
  locationsData: initialLocationsData,
  handleChange,
}) => {
  const classes = useStyles();
  const [locationsData, setLocationsData] = useState(initialLocationsData);

  const handleSwitch = (index) => {
    setLocationsData((prevLocations) => {
      const updatedLocations = [...prevLocations];
      updatedLocations[index].status = updatedLocations[index].status ? 0 : 1;
      return updatedLocations;
    });
  };

// React.useEffect(()=>{
// console.log(locationsData)
// },[locationsData])
React.useEffect(() => {
  if (initialLocationsData) {
    setLocationsData(initialLocationsData);
  }
}, [initialLocationsData]);
  const toggleCheckbox = (e, index, name) => {
    const checked = e.target.checked ? 1 : 0;
    setLocationsData((prevLocations) => {
      const updatedLocations = [...prevLocations];
      updatedLocations[index][name][0] = checked;
      return updatedLocations;
    });
  };

  return (
    <Grid container className={classes.hrLocationContainer}>
      {locationsData?.length !== 0 ? (
        <Grid container>
          {locationsData.map((data, index) => (
            <Grid item key={index} md={3} xs={12}>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <RedSwitch
                      checked={data.status ? true : false}
                      onChange={() => handleSwitch(index)}
                    />
                  }
                  label={data.location_name}
                />
              </Grid>

              <Grid item md={2} xs={2}>
                <Typography className={classes.hrLocationLabel}>
                  Payroll:{" "}
                </Typography>
                <MySelectField
                  className={classes.locationField}
                  label=""
                  name="id"
                  onChange={(e) => handleChange(e, index)}
                  value={data.payroll_id}
                  placeholder="Please Select Payroll"
                >
                  <option key={0} value={0}>
                    Select Payroll
                  </option>
                  {data.locationsPayroll.map((option) => (
                    <option value={option._id} key={option._id}>
                      {option.template_name}
                    </option>
                  ))}
                </MySelectField>
              </Grid>
              <Grid item xs={10} md={10}></Grid>

              <div
                style={{
                  width: "400px",
                  height: "110px",
                  marginBottom: "1.5rem",
                  marginLeft: "-2rem",
                }}
              >
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <FormControlLabel
                      label={`Show ${userDetails.user_name} in location schedule`}
                      control={
                        <Checkbox
                          className={`${classes.root} ${classes.FormControlLabelSecond}`}
                          name="show_location_schedule"
                          value={data.show_location_schedule[0]}
                          checked={
                            data.show_location_schedule[0] ? true : false
                          }
                          size="medium"
                          onClick={(e) =>
                            toggleCheckbox(e, index, "show_location_schedule")
                          }
                        />
                      }
                    />
                  </li>
                  <li>
                    <FormControlLabel
                      label={`Eligible ${userDetails.user_name} for open shifts`}
                      control={
                        <Checkbox
                          name="eligible_open_shifts"
                          value={data.eligible_open_shifts[0]}
                          className={`${classes.root} ${classes.FormControlLabelSecond}`}
                          checked={data.eligible_open_shifts[0] ? true : false}
                          size="medium"
                          onClick={(e) =>
                            toggleCheckbox(e, index, "eligible_open_shifts")
                          }
                        />
                      }
                    />
                  </li>
                </ul>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Grid item xs={12} style={{ padding: "5rem" }}>
          <LoadingData />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Locations;
