//Dashboard
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import productsdata from '../../../utils/productsdata';
import settingsdata from '../../../utils/settingsdata';
import { ProductCard } from '../../../components'
import { connect } from "react-redux";
import { fetchData } from '../Location/modules/actions';
import Cookies from 'universal-cookie';
import './index.css';
const cookies = new Cookies();

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4, 10),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 4),
    },
  },
  uName: {
    color: "black",
    [theme.breakpoints.down('xl')]: {
      backgroundColor: "yellow"
    },
    [theme.breakpoints.down('lg')]: {
      backgroundColor: "red"
    },
    [theme.breakpoints.down('md')]: {
      backgroundColor: "green"
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "blue"
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: "gray"
    },
  }
});


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDashboard: false,
      products: productsdata,
      settings: settingsdata,
    }
  }

  componentDidMount() {
    this.props.fetchData(false);
    var currentLocation = this.props.location.pathname;
    var isDashboard = currentLocation.includes('/settings/') ? false : true
    this.setState({
      isDashboard
    })
  }

  render() {
    const { classes } = this.props;
    const { isDashboard } = this.state;
    var myState = isDashboard ? this.state.products : this.state.settings
    return (
      <Grid
        container
        spacing={2}
        className={classes.root}
      >
        {myState.map((item, index) => {
          return (
            <Grid
              item
              key={index}
              xl={2}
              lg={2}
              md={2}
              sm={6}
              xs={6}
            >
              <ProductCard
                isActive={0}
                product={item}
              />
            </Grid>
          )
        })}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  pageNo: state.localData.pageNo,
  rowsPerPage: state.localData.rowsPerPage,
});

const ConnectWith = connect(mapStateToProps, {
  fetchData
})(Dashboard);
export default withStyles(styles)(ConnectWith);