import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme, size, bgColor, arrowColor, borderColor, buttonWidth }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    width: size.width.includes("%") ? buttonWidth : "auto",
    borderColor: borderColor,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: size || 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const Dropdown = ({
  size,
  bgColor,
  arrowColor,
  textColor,
  Icon,
  borderColor,
  rounded,
  buttonText,
  options,
  value,
  onChange,
  arrowFalseFlag,
  arrowSize,
  textSize,
  textWeight,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      setButtonWidth(buttonWidth);
    }
  }, [buttonText]);

  const selectedOption = options?.find(option => option.id === value);

  const [buttonWidth, setButtonWidth] = React.useState(null);

  // Filter options based on searchQuery
  const filteredOptions = options?.filter(option =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Button
        ref={buttonRef}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        startIcon={Icon ? <Icon /> : null}
        endIcon={
          !arrowFalseFlag ? (
            <KeyboardArrowDownIcon
              style={{
                color: arrowColor,
                fontSize: arrowSize || "23px",
              }}
            />
          ) : null
        }
        onClick={handleClick}
        style={{
          backgroundColor: bgColor,
          color: textColor,
          border: borderColor ? `1px solid ${borderColor}` : "none",
          borderRadius: rounded ? rounded : "7px",
          width: `${size.width}`,
          height: `${size.height}`,
          display: "flex",
          marginLeft:`${size.marginLeft}`,
          justifyContent: arrowFalseFlag ? "center" : "space-between",
          padding: "8px 15px 8px 12px",
        }}
      >
        <span
          style={{
            textTransform: "capitalize",
            textAlign: "left",
            fontSize: textSize || "16px",
            whiteSpace: "nowrap",
            color: textColor,
            fontWeight: textWeight || "400"
          }}
        >
          {selectedOption ? selectedOption.name : buttonText}
        </span>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        size={size}
        buttonWidth={buttonWidth}
        borderColor={borderColor}
        bgColor={bgColor}
        arrowColor={arrowColor}
        style={{ padding: "0px" }}
      >
        {/* Add search input */}
        <MenuItem disableRipple style={{ padding: "8px" }}>
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
          />
        </MenuItem>

        {/* Show filtered options */}
        {filteredOptions?.length > 0 ? (
          filteredOptions.map((option, index) => (
            <MenuItem
              onClick={() => {
                // Call onChange function with selected option
                if (onChange) onChange(option);
                handleClose();
              }}
              key={option.id}
              disableRipple
              style={{
                borderBottom:
                  index !== filteredOptions?.length - 1 &&
                  "1px solid rgba(0,0,0,0.1)",
                padding: "10px",
              }}
            >
              {option.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disableRipple style={{ padding: "10px" }}>
            No options found
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
};
