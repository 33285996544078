import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import {
  MyDatePicker,
  MyLeftModal,
  MyMultiSelect,
  MySelectField,
  MyTextfield,
} from "../../../../components";
import { ovHrV5Color } from "../../../../config";
import { hrNewUserObjectValues } from "../View/constants";
import moment from "moment";
import { utcToLocal } from "../../../../utilities";

const AddUser = ({ locationsData, rolesData, onOpenClick, onCloseClick, onSubmit }) => {
  const [addModalValues, setAddModalValues] = useState(hrNewUserObjectValues);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddModalValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeDate = (date, field) => {
    setAddModalValues((prev) => ({ ...prev, [field]: date }));
  };

  const handleUsersLocation = (event) => {
    const value = event.target.value;
    setSelectedLocationIds(Array.isArray(value) ? value : []);
  };

  const handleToggleCheckbox = (e, field) => {
    setAddModalValues((prev) => ({
      ...prev,
      [field]: e.target.checked ? 1 : 0,
    }));
  };

  const handleAddModalSubmission = () => {
    const userData = {
      ...addModalValues,
      locations: selectedLocationIds,
      joining_date: addModalValues.joining_date
        ? moment(utcToLocal(addModalValues.joining_date)).format("DD-MM-YYYY")
        : null,
      date_of_birth: addModalValues.date_of_birth
        ? moment(utcToLocal(addModalValues.date_of_birth)).format("DD-MM-YYYY")
        : null,
    };

    onSubmit(userData);
    setAddModalValues(hrNewUserObjectValues);
    setSelectedLocationIds([]);
  };

  return (
    <MyLeftModal
      bgColor={ovHrV5Color.main}
      title="Add User"
      open={onOpenClick}
      onClose={onCloseClick}
      handleSubmit={handleAddModalSubmission}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MyTextfield
            name="user_name"
            label="Name"
            product={"HR"}
            onChange={handleChange}
            type="text"
            placeholder="Enter user name"
            value={addModalValues.user_name}
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextfield
            name="user_email"
            label="Email"
            product={"HR"}
            onChange={handleChange}
            type="text"
            placeholder="Enter user email"
            value={addModalValues.user_email}
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextfield
            name="user_address1"
            label="Address"
            product={"HR"}
            onChange={handleChange}
            type="text"
            placeholder="Enter user address"
            value={addModalValues.user_address1}
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextfield
            name="user_mobile_no"
            label="Mobile no"
            product={"HR"}
            onChange={handleChange}
            type="text"
            placeholder="Enter user mobile"
            value={addModalValues.user_mobile_no}
          />
        </Grid>

        <Grid item xs={12}>
          <MyDatePicker
            name="joining_date"
            label="Joining Date"
            product={"HR"}
            onChange={(date) => handleChangeDate(date, "joining_date")}
            placeholder="Enter joining date"
            value={addModalValues.joining_date}
            
          />
        </Grid>

        <Grid item xs={12}>
          <MyDatePicker
            name="date_of_birth"
            label="Date of Birth"
            product={"HR"}
            onChange={(date) => handleChangeDate(date, "date_of_birth")}
            placeholder="Enter birth date"
            value={addModalValues.date_of_birth}
            disableFuture={true}
          />
        </Grid>

        <Grid item xs={12}>
          <MySelectField
            name="user_role"
            product={"HR"}
            onChange={handleChange}
            placeholder="Please Select role"
            value={addModalValues.user_role}
          >
            <option value={0}>Select role</option>
            {rolesData?.map((item) => (
              <option key={item._id} value={item._id}>
                {item.role_name}
              </option>
            ))}
          </MySelectField>
        </Grid>

        <Grid item xs={12}>
          <MyMultiSelect
            product={"HR"}
            options={locationsData}
            mapId="_id"
            mapName="name"
            name="selectedLocationIds"
            handleChange={handleUsersLocation}
            label="Select Locations"
            placeholder="Select Locations"
            selectedIds={selectedLocationIds}
          />
        </Grid>

        {selectedLocationIds.length > 0 && (
          <Grid item xs={12}>
            <FormControlLabel
              label={`Show ${addModalValues.user_name} in location schedule`}
              control={
                <Checkbox
                  style={{ color: ovHrV5Color.main }}
                  name="show_location_schedule"
                  checked={!!addModalValues.show_location_schedule}
                  onChange={(e) =>
                    handleToggleCheckbox(e, "show_location_schedule")
                  }
                />
              }
            />
            <FormControlLabel
              label={`Exclude ${addModalValues.user_name} from location alerts`}
              control={
                <Checkbox
                  style={{ color: ovHrV5Color.main }}
                  name="exclude_location_alert"
                  checked={!!addModalValues.exclude_location_alert}
                  onChange={(e) =>
                    handleToggleCheckbox(e, "exclude_location_alert")
                  }
                />
              }
            />
            <FormControlLabel
              label={`Exclude ${addModalValues.user_name} from timeclock errors`}
              control={
                <Checkbox
                  style={{ color: ovHrV5Color.main }}
                  name="exclude_timeclock_errors"
                  checked={!!addModalValues.exclude_timeclock_errors}
                  onChange={(e) =>
                    handleToggleCheckbox(e, "exclude_timeclock_errors")
                  }
                />
              }
            />
            <FormControlLabel
              label={`Eligible ${addModalValues.user_name} for open shifts`}
              control={
                <Checkbox
                  style={{ color: ovHrV5Color.main }}
                  name="eligible_open_shifts"
                  checked={!!addModalValues.eligible_open_shifts}
                  onChange={(e) =>
                    handleToggleCheckbox(e, "eligible_open_shifts")
                  }
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </MyLeftModal>
  );
};

export default AddUser;
