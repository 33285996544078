import {
    Edit as EditIcon,
  } from "@material-ui/icons";

export const headCells = [
  {
    id: "user_name",
    label: "Name",
    numeric: false,
    align: "left",
  },
  {
    id: "user_location",
    label: "Locations",
    numeric: false,
    align: "left",
  },
  {
    id: "app_version",
    label: "App Version",
    numeric: false,
    align: "left",
  },
  {
    id: "last_login",
    label: "Last Login",
    numeric: false,
    align: "left",
  },
  {
    id: "user_status",
    label: "Status",
    numeric: false,
    align: "left",
  },
  {
    id: "actions",
    label: "Actions",
    numeric: false,
    align: "left",
  },
];

export const hrUsersMoreMenuList = (permissionsData) => [
  {
    icon: EditIcon,
    title: "Edit User",
    sortName: "edit",
    permission: permissionsData.hr_edit_user,
  },
];

export const hrNewUserObjectValues = {
    user_name: "",
    user_email: "",
    user_address1: "",
    user_mobile_no: "",
    user_role: 0,
    show_location_schedule: 1,
    exclude_location_alert: 1,
    exclude_timeclock_errors: 1,
    eligible_open_shifts: 1,
  };