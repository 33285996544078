import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import { ovHrV5Color } from "../../../../../config";
import { utcToLocal } from "../../../../../utilities";
import { TableMyPagination, TableRecordNotFound } from "../../../../../components";
import { getComparator, stableSort } from "../../../../../utilities/TableFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px !important",
  },
  headCell: {
    padding: "0.5rem 0rem !important",
    color: "#ffffff !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
  },
  rootToolbar: {
    minHeight: "58px !important",
    padding: "8px !important",
  },
  title: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    lineHeight: "27px !important",
    fontSize: "18px !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0) !important",
    height: 1,
    margin: -1,
    overflow: "hidden !important",
    padding: 0,
    position: "absolute !important",
    top: 20,
    width: 1,
  },
  subtitle: {
    marginBottom: "3px !important",
    color: "#7C7C7C !important",
  },
  userName: {
    maxWidth: "250px !important",
    lineHeight: 3,
    display: "-webkit-box !important",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
  },
}))

const TimeOff = ({ timeoffData, pageNo, rowsPerPage }) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const headCells = [
    { id: "user_name", label: "Employee Name", numeric: false, align: "left" },
    { id: "created_at", label: "Request", numeric: false, align: "left" },
    { id: "status", label: "Status", numeric: false, align: "left" },
  ];

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Toolbar variant="regular" className={classes.rootToolbar}>
          <Typography
            display="inline"
            className={classes.title}
            style={{ marginLeft: "-1rem" }}
            variant="h4"
          >
            TIME OFF
          </Typography>
        </Toolbar>
        <Paper>
          <TableContainer>
            <Table className={classes.table} size="medium">
              <TableHead style={{ backgroundColor: ovHrV5Color.main }}>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      align={headCell.align}
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        className={classes.headCell}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell
                    style={{ paddingRight: "20px" }}
                    className={classes.headCell}
                    align="right"
                    colSpan={2}
                  />
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(timeoffData, getComparator(order, orderBy))
                  .slice(
                    pageNo * rowsPerPage,
                    pageNo * rowsPerPage + rowsPerPage
                  )
                  .map((row) => (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell style={{ verticalAlign: "baseline" }}>
                        <Typography className={classes.userName}>
                          {row.user_name}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography className={classes.subtitle}>
                          Requested:{" "}
                          {moment(utcToLocal(row.created_at)).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </Typography>
                        <Typography
                          className={classes.subtitle}
                          style={{ color: "#323C47" }}
                        >
                          {moment(utcToLocal(row.startTime)).format(
                            "DD MMM YYYY, hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(utcToLocal(row.endTime)).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </Typography>
                        <Typography className={classes.subtitle}>
                          {row.category}
                        </Typography>
                        {row.reason && (
                          <Typography className={classes.subtitle}>
                            <span style={{ color: "#323C47" }}>Reason:</span>{" "}
                            {row.reason}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {row.status && (
                          <Typography className={classes.subtitle}>
                            {row.status === 1 ? (
                              <b style={{ color: "green" }}>Approved by</b>
                            ) : (
                              <b style={{ color: "red" }}>Declined by</b>
                            )}
                            : {row.approved_by}&nbsp;at&nbsp;
                            {moment(utcToLocal(row.approved_date)).format(
                              "DD MMM YYYY, hh:mm A"
                            )}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                {timeoffData && timeoffData.length < 1 && (
                  <TableRecordNotFound colSpan={8} label="No Data Found.." />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableMyPagination count={timeoffData ? timeoffData.length : 0} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TimeOff;
