import React, { useEffect, useState } from "react";
import { Button, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { tabsLists } from "./constants";
import { usersServices } from "../../UsersList/modules/services";
import UserDetails from "./components/UserDetails";
import Locations from "./components/Locations";
import Documents from "./components/Documents";
import AutoSchedule from "./components/AutoSchedule";
import TimeOff from "./components/TimeOff";
import { history } from "../../../../history";
import moment from "moment";
import { alert } from "../../../../utilities";
import Notes from "./components/Notes";
import Notifications from "./components/Notifications";
import { editUserServices } from "./modules/services";
import { makeStyles } from "@material-ui/core/styles";
import { ovHrV5Color } from "../../../../config";

const useStyles = makeStyles((theme) => ({
  hrEditUserContainer: {
    width: "100% !important",
    height: "100% !important",
    position: "relative !important",
  },

  hrEditUserHeader: {
    height: "6rem !important",
    width: "100% !important",
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "space-between",
    padding: "0.5rem 1rem !important",
    alignItems: "center !important",
    borderBottom: "1px solid #e5e5e5",
  },

  hrEditHeading: {
    fontSize: "26px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, san-serif !important",
    color: "black !important",
    lineHeight: "30.47px !important",
  },

  hrEditUserHeaderRight: {
    display: "flex !important",
    flexDirection: "row !important",
    gap: "1rem !important",
    alignItems: "center !important",
  },

  hrEditSaveBtn: {
    backgroundColor: ovHrV5Color.main,
    color: "white !important",
    "&:hover": {
      backgroundColor: ovHrV5Color.main,
    },
    width: "7rem",
    height: "2.5rem",
  },

  hrEditUserTabContainer: {},

  hrEditUserTabs: {
    borderBottom: "1px solid #e5e5e5 !important",
  },
}));

const EditUser = ({ userId, pageNo, rowsPerPage, permissionsData }) => {
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [rolesData, setRolesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userNotes, setUserNotes] = useState([]);
  const [notesLocationsData, setNotesLocationsData] = useState([]);
  const [locationsData, setLocationsData] = useState([]);
  const [userNotificationData, setUserNotificationData] = useState([]);
  const [timeOffData, setTimeOffData] = useState([]);
  const [locationComponentValue, setLocationComponentValue] =
    useState("select");
  const [notesData, setNotesData] = useState([]);
  const [noteEdit, setNoteEdit] = useState(false);
  const [originalUserDetails, setOriginalUserDetails] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    editUserServices.fetchDataTeamRoaterLocation(userId).then((response) => {
      var notesLocationsData = [];
      if (response.data.success === 2) {
      } else {
        response.data.locationData.map((item) => {
          var obj = {
            lid: item.lid,
            location_name: item.location_name,
          };
          notesLocationsData.push(obj);
        });
        let notes = {
          lid: notesLocationsData.length > 0 ? notesLocationsData[0].lid : 0,
          notes: "",
        };
        setUserNotes(notes);
        setNotesLocationsData(notesLocationsData);
        setLocationsData(response.data.locationData);
        setLoading(false);
      }
    });

    usersServices.fetchDropDownRolesData().then((response) => {
      if (response.data.success === 2) {
      } else {
        setRolesData(response.data.result);
      }
    });

    editUserServices.fetchData(userId).then((response) => {
      var notesLocationsData = [];
      if (response.data.success === 2) {
      } else {
        locationsData?.map((item) => {
          var obj = {
            lid: item.lid,
            location_name: item.location_name,
          };
          notesLocationsData.push(obj);
        });
        let myNotes = {
          lid: notesLocationsData.length > 0 ? notesLocationsData[0].lid : 0,
          notes: "",
        };
        setUserNotes(myNotes);
        setNotesLocationsData(notesLocationsData);
        setUserDetails(response.data.userData);
        setRolesData(response.data.rolesData);
        setUserNotificationData(response.data.notificationsData);
        setTimeOffData(response.data.timeOffData);
        setLoading(false);
      }
    });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleChangeNotifications = (state) => {
    let value = userNotificationData[state] ? 0 : 1;
    setUserNotificationData({
      ...userNotificationData,
      [state]: value,
    });
  };

  const handleChangePayroll = (e, index) => {
    locationsData[index].locationsPayroll = locationsData[index]
      .locationsPayroll
      ? locationsData[index].locationsPayroll
      : "";
    locationsData[index].payroll_id = e.target.value;
    setLocationComponentValue(e.target.value);
  };

  const handleCloseClick = () => {
    history.push("/hr/users");
  };

  const handleSaveBtnClick = () => {
    if (activeTab === 0) {
      handleUpdateUserDetails();
    } else if (activeTab === 1) {
      handleUpdateUserLocations();
    } else if (activeTab === 6) {
      handleUpdateUserNotifications();
    }
  };

  const getUpdatedFields = (original, current) => {
    if (!original || !current) return {};
    return Object.keys(current).reduce((acc, key) => {
      if (current[key] !== original[key]) {
        acc[key] = current[key];
      }
      return acc;
    }, {});
  };

  const handleUpdateUserDetails = async () => {
    const updatedFields = getUpdatedFields(originalUserDetails, userDetails);
    if (Object.keys(updatedFields).length === 0) {
      alert.error("No changes detected to save.");
      return;
    }

    if (updatedFields.joining_date) {
      updatedFields.joining_date = moment(updatedFields.joining_date).format(
        "DD-MM-YYYY"
      );
    }

    setLoading(true);
    try {
      const response = await editUserServices.editTeamRoster({
        userId,
        ...updatedFields,
      });
      if (response.data.success !== 2) {
        alert.success(response.data.message);
        setOriginalUserDetails({ ...originalUserDetails, ...updatedFields });
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUserLocations = async () => {
    setLoading(true);
    var locations = [];
    locationsData.map((item) => {
      if (item.status) {
        var cloneItem = {
          lid: item.lid,
          payroll_id: item.payroll_id,
          show_location_schedule: item.show_location_schedule,
          exclude_location_alert: item.exclude_location_alert,
          exclude_timeclock_errors: item.exclude_timeclock_errors,
          eligible_open_shifts: item.eligible_open_shifts,
          status: item.status,
          location_name: item.location_name,
        };
        locations.push(cloneItem);
      }
    });

    var datawithoutPayrollId = [];
    locations.map((dxd) => {
      if (dxd.payroll_id === "" && dxd.status === 1) {
        datawithoutPayrollId.push(dxd);
      }
    });

    if (datawithoutPayrollId.length > 0) {
      alert.error(
        `please select Payroll rule for ${datawithoutPayrollId[0].location_name}`
      );
      setLoading(false);
    } else {
      let data = {
        uid: userId,
        locationsData: locations,
      };
      editUserServices.updateLocationsData(data).then((response) => {
        if (response.data.success === 2) {
        } else {
          alert.success(response.data.message);
          setLoading(false);
        }
      });
    }
  };

  const handleUpdateUserNotifications = async () => {
    setLoading(true);
    const response = await editUserServices.updateNotifications(
      userId,
      userNotificationData
    );
    if (response.data.success !== 2) {
      alert.success(response.data.message);
    }
    setLoading(false);
  };

  const handleUserNotes = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUserNotes({ ...userNotes, [name]: value });
  };

  const handleAddNotes = async () => {
    if (!userNotes.notes.trim()) {
      alert.error("Notes cannot be empty.");
      return;
    }
    const data = { uid: userId, lid: userNotes.lid, notes: userNotes.notes };
    console.log("Sending payload to API:", data);
    setLoading(true);
    try {
      const response = await editUserServices.addNotes(data);
      if (response.data.success !== 2) {
        setNotesData((prev) => [...prev, response.data.post]);
        alert.success("Note added successfully.");
        setUserNotes({ lid: userNotes.lid, notes: "" });
      } else {
        alert.error(response.data.message);
      }
    } catch (error) {
      console.error("Error adding note:", error);
      alert.error("An error occurred while adding the note.");
    } finally {
      setLoading(false);
    }
  };

  const handleUserEditNotes = async () => {
    if (!userNotes.notes.trim()) {
      alert.error("Notes cannot be empty.");
      return;
    }

    const data = {
      id: userNotes._id,
      lid: userNotes.lid,
      notes: userNotes.notes,
    };

    setLoading(true);
    try {
      const response = await editUserServices.editNotes(data);
      if (response.data.success !== 2) {
        setNotesData((prevNotes) =>
          prevNotes.map((note) =>
            note._id === data.id ? { ...note, ...data } : note
          )
        );
        alert.success("Note updated successfully.");
        setNoteEdit(false);
        setUserNotes({ lid: notesLocationsData[0].lid, notes: "" });
      } else {
        alert.error(response.data.message);
      }
    } catch (error) {
      console.error("Error editing note:", error);
      alert.error("An error occurred while editing the note.");
    } finally {
      setLoading(false);
    }
  };

  const handleUserNotesDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this note?")) return;

    setLoading(true);
    try {
      const response = await editUserServices.deleteNotes(id);
      if (response.data.success !== 2) {
        setNotesData((prevNotes) =>
          prevNotes.filter((note) => note._id !== id)
        );
        alert.success("Note deleted successfully.");
      } else {
        alert.error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      alert.error("An error occurred while deleting the note.");
    } finally {
      setLoading(false);
    }
  };

  const handleUserNotesModification = (data) => {
    console.log("Updated userNotes state:", data);
    setNoteEdit(true);
    setUserNotes({
      _id: data._id,
      lid: data.lid,
      notes: data.notes,
    });
  };

  const handleChangeText = (e) => {
    let state = e.target.name;
    let value = e.target.value;

    if (state) {
      const validMin = value.match(/^[0-9]+$/);
      if (!validMin && value !== "") {
        alert("Not a valid number");
      }
    }
    setUserNotificationData((prevState) => ({
      ...prevState,
      [state]: value,
    }));
  };

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Locations
            userDetails={userDetails}
            locationsData={locationsData}
            handleChange={handleChangePayroll}
          />
        );
      case 2:
        return <Documents userDetails={userDetails} />;
      case 3:
        return <AutoSchedule />;
      case 4:
        return (
          <TimeOff
            timeoffData={timeOffData}
            pageNo={pageNo}
            rowsPerPage={rowsPerPage}
          />
        );
      case 5:
        return (
          <Notes
            notesData={notesData}
            locationsData={notesLocationsData}
            userNotes={userNotes}
            handleNotes={handleUserNotes}
            editNotes={handleUserEditNotes}
            addNotes={handleAddNotes}
            noteEdit={noteEdit}
            deleteNotes={handleUserNotesDelete}
            modifyNotes={handleUserNotesModification}
          />
        );
      case 6:
        return (
          <Notifications
            handleChangeNotifications={handleChangeNotifications}
            notificationsData={userNotificationData}
            permissionsData={permissionsData}
            handleChangeData={handleChange}
            handleChangeText={handleChangeText}
          />
        );
      default:
        return (
          <UserDetails
            userDetails={userDetails}
            rolesData={rolesData}
            handleChangeDate={(date, state) => {
              setUserDetails((prev) => ({ ...prev, [state]: date }));
            }}
            handleChange={(e) => {
              const { name, value } = e.target;
              setUserDetails((prev) => ({ ...prev, [name]: value }));
            }}
          />
        );
    }
  };

  const handleChangeTabs = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Paper className={styles.hrEditUserContainer}>
      <div className={styles.hrEditUserHeader}>
        <Typography className={styles.hrEditHeading}>My Account</Typography>
        <div className={styles.hrEditUserHeaderRight}>
          <Button className={styles.hrEditSaveBtn} onClick={handleSaveBtnClick}>
            Save
          </Button>
          <Button className={styles.hrEditSaveBtn} onClick={handleCloseClick}>
            Close
          </Button>
        </div>
      </div>
      <div className={styles.hrEditUserTabContainer}>
        <Tabs
          size="small"
          value={activeTab}
          onChange={handleChangeTabs}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          className={styles.hrEditUserTabs}
        >
          {tabsLists.map((item, index) => (
            <Tab
              key={index}
              style={{
                color: index === activeTab ? "rgba(0, 126, 99, 1)" : "black",
              }}
              className={styles.hrEditUserTab}
              label={item.title}
            />
          ))}
        </Tabs>
        {renderContent()}
      </div>
    </Paper>
  );
};

export default EditUser;
