import {
  SET_LOCATION_LOADING,
  SET_LOCATION,
  ADD_LOCATION,
  EDIT_LOCATION,
  DELETE_LOCATION,
  SET_ASSIGN_LOCATION,
  SET_BUSSINESS_CATEGORIES,
  SET_BUSSINESS_TYPES,
} from "./constants";

const initialState = {
  isLoading: true,
  assignedLocations: [],
  businessTypes: [],
  businessCategory: [],
  locationData: [],
  locations_count: 6,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOCATION_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_ASSIGN_LOCATION:
      return {
        ...state,
        assignedLocations: payload.locationData,
      };
    case SET_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: payload.result,
        page: payload.currentPage,
        totalPages: payload.totalPages,
        rowsPerPage: payload.nPerPage,
        totalCount: payload.totalCount,
      };
    case SET_BUSSINESS_CATEGORIES:
      return {
        ...state,
        businessCategory: payload.result,
      };

    case SET_BUSSINESS_TYPES:
      return {
        ...state,
        businessTypes: payload.result,
      };
    case ADD_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: [...state.locationData, payload],
      };
    case EDIT_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: state.locationData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };
    case DELETE_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: state.locationData.filter((item) => item._id !== payload),
      };
    default:
      return state;
  }
};

export default Reducer;
