import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import EditIcon from "@mui/icons-material/Edit";
// import Divider from "@mui/material/Divider";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Popover} from "@material-ui/core";
import DatePicker from "react-datepicker";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme, size, bgColor, arrowColor, borderColor, buttonWidth }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    width: size.width.includes("%") ? buttonWidth : "auto",
    borderColor: borderColor,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: size || 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const DropdownDateFilter = ({
  size,
  bgColor,
  arrowColor,
  textColor,
  Icon,
  customStartDate,
  borderColor,
  customEndDate,
  rounded,
  buttonText,
  options,
  value,
  onChange,
  arrowFalseFlag,
  onChangeDateBetween,
  arrowSize,
  textSize,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOptionValue, setSelectedOption] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClosepopover = () => {
    setSelectedOption(null)
  }
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      setButtonWidth(buttonWidth);
    }
  }, [buttonText]);



  const selectedOption = options?.find(option => option.id === value);

  React.useEffect(() => {
    if (selectedOption !== undefined) {
      setSelectedOption(true);
    }
  }, [selectedOption]);


  const [buttonWidth, setButtonWidth] = React.useState(null);


  return (
    <div style={{textAlign:"justify"}}>
      <Button
        ref={buttonRef}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        startIcon={Icon ? <Icon /> : null}
        endIcon={
          !arrowFalseFlag ? (
            <KeyboardArrowDownIcon
              style={{
                color: arrowColor,
                fontSize: arrowSize || "23px",
              }}
            />
          ) : null
        }
        onClick={handleClick}
        style={{
          backgroundColor: bgColor,
          color: textColor,
          border: borderColor ? `1px solid ${borderColor}` : "none",
          borderRadius: rounded ? rounded : "7px",
          width: `${size.width}`,
          height: `${size.height}`,
          marginLeft:`${size.marginLeft}`,
          display: `${size.display}`,
          justifyContent: arrowFalseFlag ? "center" : "space-between",
          padding: "8px 15px 8px 12px",
        }}
      >
        <span
          style={{
            textTransform: "capitalize",
            textAlign: "left",
            fontSize: textSize || "13px",
            color:"lightgray",
            whiteSpace:"nowrap"
          }}
        >
          {value  ? value: buttonText}
        </span>
      </Button>
      {/* <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        size={size}
        buttonWidth={buttonWidth}
        borderColor={borderColor}
        bgColor={bgColor}
        arrowColor={arrowColor}
        style={{ padding: "0px" }}
      >
        {options &&
          options?.map((option, index) => (
            <MenuItem
            onClick={() => {
              if (onChange) onChange(option);
              // handleClose();
            }}
            key={option.id}
            disableRipple
            style={{
              borderBottom:
                index !== options.length - 1 && '1px solid rgba(0,0,0,0.1)',
              padding: '10px',
              display: 'flex', // Flex display to align items
              justifyContent: 'space-between', // Align items horizontally
              alignItems: 'center', // Align items vertically
            }}
          >
        
            {option.name}
      

            <KeyboardArrowRightIcon />
          </MenuItem>
          ))}
      </StyledMenu> */}
      <Popover
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // open={Boolean(selectedOptionValue)}
        // anchorEl={buttonRef.current}
        // onClose={handleClosepopover}
        anchorOrigin={{
          vertical: 'top',     // Adjust according to your preference
          horizontal: 'right', // Adjust according to your preference
        }}
        transformOrigin={{
          vertical: 'top',     // Adjust according to your preference
          horizontal: 'left',  // Adjust according to your preference
        }}
      >
        <div style={{ padding: '10px' }}>
          {/* Render your TimePicker component here */}
          <DatePicker
                  selected={customStartDate}
                  onChange={onChangeDateBetween}
                   startDate={customStartDate}
                  endDate={customEndDate}
                  selectsRange
                  inline
            />
        </div>
      </Popover>
    </div>
  );
};

