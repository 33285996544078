import {
  FileCopy as CopyIcon,
  PersonAdd as UserIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";

export const headCells = [
  {
    id: "role_name",
    label: "Name",
    numeric: false,
    align: "left",
  },
  {
    id: "role_description",
    label: "Description",
    numeric: false,
    align: "left",
  },
  {
    id: "user_role_count",
    label: "Assigned Users",
    numeric: false,
    align: "left",
  },
  {
    id: "created_at",
    label: "Created at",
    numeric: false,
    align: "left",
  },
  {
    id: "updated_at",
    label: "Updated at",
    numeric: false,
    align: "left",
  },
  {
    id: "actions",
    label: "Actions",
    numeric: false,
    align: "left",
  },
];

export const menuListData = (permissionsData) => [
  {
    icon: UserIcon,
    title: "Assigned Users",
    sortName: "assign",
    permission: permissionsData.common_roles_view_assigned,
  },
  {
    icon: CopyIcon,
    title: "Clone Role",
    sortName: "clone",
    permission: true,
  },
  {
    icon: EditIcon,
    title: "Edit Role",
    sortName: "edit",
    permission: permissionsData.hr_edit_role,
  },
  {
    icon: DeleteIcon,
    title: "Delete Role",
    sortName: "delete",
    permission: permissionsData.hr_delete_role,
  },
];

export const permissionsObjectForAddRole = {
  role_name: "",
  role_description: "",
  role_parentId: "",
  //feedback initial count
  feedback_Followups_Data_Count: 0,
  feedback_Campaigns_Data_Count: 0,
  feedback_QR_Locations_Data_Count: 0,
  feedback_Settings_Data_Count: 0,

  //Rating
  rating_form_Data_Count: 0,
  rating_form: 0,
  rating_form_add: 0,
  rating_form_delete: 0,

  ///HR Roles
  roles_and_permissions_Data_Count: 0,
  users_HR_Data_Count: 0,
  groups_HR_Data_Count: 0,
  boarding_HR_Data_Count: 0,
  hiring_HR_Data_Count: 0,
  hierarchy_HR_Data_Count: 0,
  template_HR_Data_Count: 0,
  evaluation_HR_Data_Count: 0,
  events_HR_Data_Count: 0,
  hr_add_candidate: 0,
  hr_add_evaluation_form: 0,
  hr_add_evaluation_form_response: 0,
  hr_add_event: 0,
  hr_add_event_category: 0,
  hr_add_form_stage: 0,
  hr_add_interview: 0,
  hr_add_job_posting: 0,
  hr_add_observation: 0,
  hr_add_observation_category: 0,
  hr_add_offboarding_form: 0,
  hr_add_onboarding_form: 0,
  hr_add_promotion_rule: 0,
  hr_add_question: 0,
  hr_add_question_category: 0,
  hr_add_role: 0,
  hr_add_template: 0,
  hr_add_update_boarding_answer: 0,
  hr_add_update_offboarding_answer: 0,
  hr_add_update_onboarding_answer: 0,
  hr_add_user: 0,
  hr_assigned_by_me_evaluation_forms: 0,
  hr_assigned_to_me_evaluation_forms: 0,
  hr_boarding: 0,
  hr_create_group: 0,
  hr_create_template_list: 0,
  hr_delete_boarding_answer: 0,
  hr_delete_boarding_form: 0,
  hr_delete_candidate: 0,
  hr_delete_evaluation_form: 0,
  hr_delete_event: 0,
  hr_delete_event_category: 0,
  hr_delete_group: 0,
  hr_delete_hierarchy: 0,
  hr_delete_interview: 0,
  hr_delete_job_posting: 0,
  hr_delete_observation: 0,
  hr_delete_observation_category: 0,
  hr_delete_offboarding_form: 0,
  hr_delete_onboarding_form: 0,
  hr_delete_promotion_rule: 0,
  hr_delete_question: 0,
  hr_delete_question_category: 0,
  hr_delete_role: 0,
  hr_delete_user: 0,
  hr_edit_candidate: 0,
  hr_edit_evaluation_form: 0,
  hr_edit_evaluation_form_response: 0,
  hr_edit_event: 0,
  hr_edit_event_category: 0,
  hr_edit_group: 0,
  hr_edit_hierarchy: 0,
  hr_edit_interview: 0,
  hr_edit_job_posting: 0,
  hr_edit_observation: 0,
  hr_edit_observation_category: 0,
  hr_edit_promotion_rule: 0,
  hr_edit_question: 0,
  hr_edit_question_category: 0,
  hr_edit_role: 0,
  hr_edit_user: 0,
  hr_evalution: 0,
  hr_events: 0,
  hr_graduation_event: 0,
  hr_hierarchy: 0,
  hr_hiring: 0,
  hr_observation_list: 0,
  hr_publish_form_stages: 0,
  hr_roles: 0,
  hr_save_boarding_user_info: 0,
  hr_save_evaluation_settings: 0,
  hr_template: 0,
  hr_update_layout: 0,
  hr_users: 0,
  hr_view_all_users_not_assigned_promotion_status: 0,
  hr_view_birthdays: 0,
  hr_view_boarding_answers: 0,
  hr_view_candidates: 0,
  hr_view_evaluation_form_response: 0,
  hr_view_evaluation_forms_myself: 0,
  hr_view_evaluation_forms_team: 0,
  hr_view_evaluation_settings: 0,
  hr_view_events: 0,
  hr_view_form_stages: 0,
  hr_view_group_members: 0,
  hr_view_groups: 0,
  hr_view_hierarchy: 0,
  hr_view_interviews: 0,
  hr_view_job_postings: 0,
  hr_view_observation_categories: 0,
  hr_view_observations: 0,
  hr_view_offboarding_answers: 0,
  hr_view_offboarding_layout_forms: 0,
  hr_view_offboarding_track: 0,
  hr_view_offboarding_user_trackers: 0,
  hr_view_onboarding_answers: 0,
  hr_view_onboarding_layout_forms: 0,
  hr_view_onboarding_track: 0,
  hr_view_onboarding_user_trackers: 0,
  hr_view_organizational_events: 0,
  hr_view_promotion_rules: 0,
  hr_view_question_categories: 0,
  hr_view_questions: 0,
  hr_view_roles: 0,
  hr_view_team_life_events: 0,
  hr_view_team_milestones: 0,
  hr_view_templates: 0,
  hr_view_templates_list: 0,
  hr_view_users: 0,
  hr_view_work_anniversaries: 0,

  ////NEWS
  news_my_news_Data_Count: 0,
  news_news_builder_Data_Count: 0,
  view_all_my_news: 0,
  view_my_news_details: 0,
  view_all_news_builder: 0,
  news_builder_details: 0,
  create_news_builder: 0,
  edit_news_builder: 0,
  delete_news_builder: 0,

  common_Company_Data_Count: 0,
  common_Ideas_Data_Count: 0,
  common_Location_Data_Count: 0,
  common_Payroll_Data_Count: 0,
  common_Roles_Data_Count: 0,
  common_Ticket_Data_Count: 0,
  common_Users_Data_Count: 0,
  common_billings: 0,
  common_color: 0,
  common_company: 0,
  common_company_delete: 0,
  common_company_edit: 0,
  common_coupons: 0,
  common_hierarchy: 0,
  common_ideas: 0,
  common_ideas_add: 0,
  common_ideas_delete: 0,
  common_ideas_edit: 0,
  common_location: 0,
  common_location_add: 0,
  common_location_delete: 0,
  common_location_edit: 0,
  common_payroll: 0,
  common_payroll_add: 0,
  common_payroll_delete: 0,
  common_payroll_edit: 0,
  common_payroll_viewHistory: 0,
  common_products: 0,
  common_roles: 0,
  common_roles_add: 0,
  common_roles_delete: 0,
  common_roles_edit: 0,
  common_roles_view: 0,
  common_roles_view_assigned: 0,
  common_tickets: 0,
  common_tickets_add: 0,
  common_tickets_delete: 0,
  common_tickets_edit: 0,
  common_users: 0,
  common_users_active: 0,
  common_users_add: 0,
  common_users_delete: 0,
  common_users_edit: 0,
  common_users_is_view: 0,
  common_users_loginas: 0,
  common_users_view: 0,

  feedback_dashboard: 0,
  feedback_team: 0,
  feedback_reports: 0,

  feedback_followups_approve: 0,
  feedback_followups_view_detail: 0,
  feedback_followups_delete: 0,
  feedback_campaign_add: 0,
  feedback_campaign_edit: 0,
  feedback_campaign_delete: 0,
  feedback_campaign_assign_location: 0,
  feedback_qr_locations_devices: 0,
  feedback_qr_locations_view_history: 0,
  feedback_qr_locations_edit: 0,
  feedback_settings_sms: 0,
  feedback_settings_percentage: 0,
  feedback_settings_timezone: 0,
  feedback_settings_notification_dashboard: 0,
  feedback_settings_notification_email: 0,
  feedback_settings_notification_push: 0,
  feedback_settings_notification_mobile_push: 0,

  elearning_Course_Data_Count: 0,
  elearning_Feedback_Data: 0,
  elearning_Feedback_Data_Count: 0,
  elearning_Grading_Data_Count: 0,
  elearning_Helpdesk_Data_Count: 0,
  elearning_Helpdesk_Data_Helpdesk_Count: 0,
  elearning_Library_Data_Count: 0,
  elearning_Progress_Data_Count: 0,
  elearning_Setting_Data: 0,
  elearning_assign_library: 0,
  elearning_category: 0,
  elearning_course: 0,
  elearning_course_add: 0,
  elearning_course_assign: 0,
  elearning_course_delete: 0,
  elearning_course_edit: 0,
  elearning_dashboard: 0,
  elearning_dashboard_refresh_settings: 0,
  elearning_earning: 0,
  elearning_earning_Data_Count: 0,
  elearning_earning_settings: 0,
  elearning_feedback: 0,
  elearning_feedback_update_status: 0,
  elearning_grading: 0,
  elearning_grading_my_grading: 0,
  elearning_grading_settings: 0,
  elearning_grading_team_grading: 0,
  elearning_helpdesk: 0,
  elearning_helpdesk_add: 0,
  elearning_helpdesk_assign: 0,
  elearning_helpdesk_delete: 0,
  elearning_helpdesk_devices: 0,
  elearning_helpdesk_edit: 0,
  elearning_helpdesk_view_lessons: 0,
  elearning_library: 0,
  elearning_library_category_add: 0,
  elearning_library_category_delete: 0,
  elearning_library_category_edit: 0,
  elearning_library_copy_link: 0,
  elearning_library_lessons_add: 0,
  elearning_library_lessons_delete: 0,
  elearning_library_lessons_edit: 0,
  elearning_library_lessons_view_link_courses: 0,
  elearning_my_earnings: 0,
  elearning_my_library: 0,
  elearning_mycourse: 0,
  elearning_myhelpdesk: 0,
  elearning_progress: 0,
  elearning_reports: 0,
  elearning_settings: 0,
  elearning_settings_Count: 0,
  elearning_submit_feedback: 0,
  elearning_team: 0,
  elearning_team_earnings: 0,
  elearning_view_feedback: 0,
  eleaning_assign_library: 0,
  assignHelpdesk: [],

  level_0_approval: 0,
  level_2_approval: 0,
  level_3_approval: 0,
  payrun_reset: 0,

  correction_Level_0_Approval: 0,
  correction_Level_2_Approval: 0,
  correction_Level_3_Approval: 0,
  delete_correction_request: 0,
  correction_reset: 0,
  payrun_reset_button: 0,

  scheduler_CoverShifts_Data_Count: 0,
  scheduler_MyAvailability_Data_Count: 0,
  scheduler_Payroll_Data_Count: 0,
  scheduler_Payroll_Summary_Data_Count: 0,
  scheduler_Roles_Data_Count: 0,
  scheduler_Schedule_Data_Count: 0,
  scheduler_TeamAvailability_Data_Count: 0,
  scheduler_TimeOffRequests_Data_Count: 0,
  scheduler_TimecardReport_Data_Count: 0,
  scheduler_Timesheet_Data_Count: 0,
  scheduler_TradeShifts_Data_Count: 0,
  scheduler_access_level: 0,
  scheduler_availability: 0,
  scheduler_correction_request_Data_Count: 0,
  scheduler_correction_request_my: 0,
  scheduler_correction_request_team: 0,
  scheduler_cover_shift: 0,
  scheduler_covershifts_add: 0,
  scheduler_covershifts_approve: 0,
  scheduler_covershifts_delete: 0,
  scheduler_covershifts_edit: 0,
  scheduler_covershifts_view: 0,
  scheduler_dashboard: 0,
  scheduler_manager_alerts: 0,
  scheduler_myavailability_add: 0,
  scheduler_myavailability_delete: 0,
  scheduler_myavailability_edit: 0,
  scheduler_payroll_add: 0,
  scheduler_payroll_assign: 0,
  scheduler_payroll_change_export: 0,
  scheduler_payroll_code_add: 0,
  scheduler_payroll_code_delete: 0,
  scheduler_payroll_code_edit: 0,
  scheduler_payroll_delete: 0,
  scheduler_payroll_edit: 0,
  scheduler_payroll_id: 0,
  scheduler_payroll_reset_data_and_approvals: 0,
  scheduler_payroll_run_Data_Count: 0,
  scheduler_payroll_summary: 0,
  scheduler_payroll_template: 0,
  scheduler_payroll_view_assign: 0,
  scheduler_payroll_view_export: 0,
  scheduler_payroll_view_other_payroll: 0,
  scheduler_roles: 0,
  scheduler_roles_add: 0,
  scheduler_roles_delete: 0,
  scheduler_roles_edit: 0,
  scheduler_schedule: 0,
  scheduler_schedule_add_employee: 0,
  scheduler_schedule_add_events: 0,
  scheduler_schedule_add_shifts: 0,
  scheduler_schedule_copy: 0,
  scheduler_schedule_delete_events: 0,
  scheduler_schedule_delete_shifts: 0,
  scheduler_schedule_drag: 0,
  scheduler_schedule_edit_events: 0,
  scheduler_schedule_edit_shifts: 0,
  scheduler_schedule_print: 0,
  scheduler_schedule_publish: 0,
  scheduler_schedule_view_events: 0,
  scheduler_schedule_view_others_schedule: 0,
  scheduler_settings: 0,
  scheduler_team_availability: 0,
  scheduler_teamavailability_add: 0,
  scheduler_teamavailability_approve: 0,
  scheduler_teamavailability_delete: 0,
  scheduler_teamavailability_edit: 0,
  scheduler_timecard_report: 0,
  scheduler_timecardreport_view_detail: 0,
  scheduler_timeoff_request: 0,
  scheduler_timeoffrequests_add: 0,
  scheduler_timeoffrequests_approve: 0,
  scheduler_timeoffrequests_delete: 0,
  scheduler_timeoffrequests_edit: 0,
  scheduler_timeoffrequests_filter: 0,
  scheduler_timeoffrequests_view: 0,
  scheduler_timesheet: 0,
  scheduler_timesheet_add: 0,
  scheduler_timesheet_download: 0,
  scheduler_timesheet_edit: 0,
  scheduler_timesheet_filter: 0,
  scheduler_timesheet_fix_issue: 0,
  scheduler_timesheet_lock: 0,
  scheduler_timesheet_view: 0,
  scheduler_timesheet_view_edit_history: 0,
  scheduler_timesheet_view_others_timesheet: 0,
  scheduler_timesheet_view_timecard_notes: 0,
  scheduler_trade_shift: 0,
  scheduler_tradeshifts_add: 0,
  scheduler_tradeshifts_approve: 0,
  scheduler_tradeshifts_delete: 0,
  scheduler_tradeshifts_edit: 0,
  scheduler_tradeshifts_view: 0,
};
