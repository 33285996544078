import React, { Component } from "react";
import OvHrSidebarLinkv5 from "./OvHrSidebarLinkv5";
import { OvHrRoutes as initialRoutes } from "../../routes/OvHrRoutes";

export default class OvHrSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newRoutes: initialRoutes,
    };
  }

  addAllEventsToRoutes(routes, allEventsData) {
    return routes.map((route) => {
      if (route.label === "Events" && Array.isArray(route.childData)) {
        const eventChildData = allEventsData.map((item) => ({
          label: item.name,
          path: `/hr/events/${item?.sluggedName}`,
          id:item?._id,
        }));
        return {
          ...route,
          childData: [...route.childData, ...eventChildData],
        };
      }
      return route;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allEventsData !== this.props.allEventsData) {
      console.log("this.props.allEventsData", this.props.allEventsData);
      const updatedRoutes = this.addAllEventsToRoutes(
        initialRoutes,
        [this.props.allEventsData[1]]
      );
      this.setState({ newRoutes: updatedRoutes });
    }
  }


  handleToggleRoutes = (index, isOpen) => {
    const newRoutes = this.state.newRoutes.map((item, myIndex) => {
      const cloneItem = { ...item };
      if (myIndex === index) {
        cloneItem.isOpen = !isOpen;
      }
      return cloneItem;
    });

    this.setState({
      newRoutes: newRoutes,
    });
  };

  render() {
    console.log("updatedRoutes::", this.state.newRoutes);
    return (
      <div>
        {this.state.newRoutes.map((page, key) => (
          <OvHrSidebarLinkv5
            handleToggleRoutes={() => this.handleToggleRoutes(key, page.isOpen)}
            clickLink={this.props.clickLink}
            key={key}
            location={this.props.location}
            isSidebarOpen={this.props.isSidebarOpen}
            {...page}
          />
        ))}
      </div>
    );
  }
}
