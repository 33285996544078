import React, { Component } from "react";
// import { Grid } from "@mui/material";
import { LessonTab } from "./utils/LessonTab";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

const SlidesContainer = styled.div`
  background-color: rgba(247, 248, 252, 1);
  height: 100%;
  width: 30%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  padding: 5% 5px 10px 5px;
  justify-content: start;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    min-width: auto;
    height: 400px;
    justify-content: center;
  }
`;

const SlideContentContainer = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  // display: flex;
  padding: 30px 5px 5px 5px;
  // justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Slides = styled.div`
  overflow-y: scroll;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: row;
  }
`;

const SlidesChildContainer = styled.div`
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    overflow: auto;
    width: 100%;
    margin: 0 auto;
  }
`;

const InlessonQuizzesAttempts = styled.div`
  padding: 20px 30px
`;

const InlessonQuizzesAttemptsText = styled.p`
  margin: 0px;
  font-size: 15px;
`;

const commonStyle = {
  border: "2px solid lightgray",
  height: "476px",
  width: "90%",
  minWidth: "350px",
  maxWidth: "1200px",
  overflowY: "scroll",
  textAlign: "left",
  padding: "40px 20px",
  boxSizing: "border-box",
  visibility: "block",
  color: "#000000",
  fontSize: "12px",
  fontWeight: "400",
  borderRadius: "5px",
  position: "relative",
  overflow: "auto",
  margin: "0 auto",
};

class LessonSlides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1,
      description: "",
      videoUrl: "",
      documentUrl: "",
      imageUrl: "",
      total_attempts: 0
    };
    this.sliderMobileRef = React.createRef();
  }

  componentDidMount = () => {
    // this.setState({
    //   description: this.props.LessonDetail.lessonData[0]
    //     ? this.props.LessonDetail.lessonData[0].content
    //     : "",
    // });
    
    if (this.props.LessonDetail.lessonData[0].total_attempts > 0) {
      this.setState({
        total_attempts: this.props.LessonDetail.lessonData[0].total_attempts
      });
    }

    if (this.props.LessonDetail.lessonData[0].lesson_type === 1) {
      this.setState({
        description: this.props.LessonDetail.lessonData[0]?.content,
        videoUrl: "",
        documentUrl: "",
      });
    } else if (this.props.LessonDetail.lessonData[0].lesson_type === 2) {
      this.setState({
        description: "",
        videoUrl: this.props.LessonDetail.lessonData[0]?.videoUrl,
        documentUrl: "",
      });
    } else if (this.props.LessonDetail.lessonData[0].lesson_type === 3) {
      this.setState({
        description: "",
        videoUrl: "",
        documentUrl: this.props.LessonDetail.lessonData[0]?.documenturl,
      });
    } else if (this.props.LessonDetail.lessonData[0].lesson_type === 4) {
      this.setState({
        description: "",
        videoUrl: "",
        documentUrl: "",
        imageUrl: this.props.LessonDetail.lessonData[0]?.imageUrl,
      });
    }
  };

  handleTabClick = (selectedTab, val) => {
    let newState = {
      selectedTab: selectedTab,
      description: "",
      videoUrl: "",
      documentUrl: "",
      total_attempts: val.total_attempts
    };

    if (val.lesson_type === 1) {
      newState.description = val?.content;
    } else if (val.lesson_type === 2) {
      newState.videoUrl = val?.videoUrl;
    } else if (val.lesson_type === 3) {
      newState.documentUrl = val?.documenturl;
    } else if (val.lesson_type === 4) {
      newState.imageUrl = val?.imageUrl;
    }

    if (val?.slide_questions?.length > 0) {
      newState.total_attempts = val?.total_attempts;
    }

    this.setState(newState);
  };

  getFileExtension = (url) => {
    // Extract the part of the URL after the last period
    const extension = url?.split(".").pop();

    // Return the extension if it exists, otherwise return an empty string
    return extension?.includes("/") ? "" : extension;
  };

  render() {
    const { classes, LessonDetail } = this.props;
    var extension = this.getFileExtension(this.state.documentUrl);
    return (
      <Container>
        <SlidesContainer>
          <div
            className={"d-flex justifyContent center"}
          // style={{ marginLeft: "34px" }}
          >
            <p className={"mb-0 " + classes.lessonTitleS}>
              {LessonDetail.lesson_name} Slides
            </p>
          </div>
          <SlidesChildContainer>
            <Slides ref={this.sliderMobileRef}>
              {LessonDetail.lessonData.map((val, index) =>
                val.lesson_type === 5 ? null : (
                  <div
                    key={index}
                    onClick={() => this.handleTabClick(index + 1, val)}
                  >
                    <LessonTab
                      id={index + 1}
                      lessonNo={val.lesson_type}
                      selected={this.state.selectedTab}
                      data={val.content}
                      val={val}
                    />
                  </div>
                )
              )}
            </Slides>
          </SlidesChildContainer>
        </SlidesContainer>

        <SlideContentContainer>
          {
            this.state.total_attempts > 0 && (
              <InlessonQuizzesAttempts>
                <InlessonQuizzesAttemptsText><b>In lesson quiz total attempts:</b> {this.state.total_attempts}</InlessonQuizzesAttemptsText>
              </InlessonQuizzesAttempts>
            )
          }

          {this.state.videoUrl !== "" ? (
            <div style={commonStyle}>
              <video
                style={{ width: "100%", height: "350px" }}
                id="prevideo"
                onMouseOver={() => {
                  this.setState({ onVideoOver: true });
                }}
                ref={this.videoRef}
                controls={true}
              >
                <source
                  className="preview_video_src"
                  src={
                    this.state.videoUrl &&
                    (this.state.videoUrl.startsWith("https")
                      ? this.state.videoUrl
                      : this.state.videoUrl.startsWith("http:")
                        ? this.state.videoUrl.replaceAll("http:", "https:")
                        : "https://" + this.state.videoUrl)
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          ) : this.state.description !== "" ? (
            <div
              style={{
                ...commonStyle,
                overflowWrap: "break-word",
                wordBreak: "break-word",
              }}
              dangerouslySetInnerHTML={{ __html: this.state.description }}
            />
          ) : this.state.documentUrl !== "" ? (
            <div style={{ ...commonStyle, textAlign: "center" }}>
              {this.state.documentUrl &&
                (extension === "pptx" || extension === "ppt" ? (
                  <iframe
                    id="myIframe"
                    className="preview_pptx_src"
                    height="350px"
                    width="100%"
                    src={
                      `https://view.officeapps.live.com/op/embed.aspx?src=` +
                      this.state.documentUrl
                    }
                    title="description"
                  />
                ) : extension === "pdf" ? (
                  <iframe
                    src={this.state.documentUrl}
                    // src={this.state.documentUrl}
                    className="preview_pptx_src"
                    // toolbar={0}
                    id="myIframe"
                    // navpanes="0"
                    width="100%"
                    height="400px"
                    style={{ border: "none" }}
                    title="Document Viewer"
                  />
                ) : (
                  "File type is incorrect"
                ))}
            </div>
          ) : (
            this.state.imageUrl !== "" && (
              <div style={commonStyle}>
                <img
                  alt=""
                  style={{
                    width: "100%",
                    height: "400px",
                    objectFit: "contain",
                  }}
                  src={
                    this.state.imageUrl &&
                    (this.state.imageUrl?.startsWith("https")
                      ? this.state.imageUrl
                      : this.state.imageUrl?.startsWith("http:")
                        ? this.state.imageUrl?.replaceAll("http:", "https:")
                        : "https://" + this.state.imageUrl)
                  }
                />
              </div>
            )
          )}
        </SlideContentContainer>
      </Container>
    );
  }
}

export default LessonSlides;
