import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { documentStyles } from "../Styles";

const Documents = ({ userDetails }) => {
  const classes = documentStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item md={12} xs={12}>
        <Typography variant="h3" color="textPrimary">
          Forms
        </Typography>
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography variant="h3" color="textPrimary">
          {"Upload " + userDetails.user_name + "'s I-9 Form"}
        </Typography>
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography variant="h3" color="textPrimary">
          {"Upload " + userDetails.user_name + "'s W-4 Form"}
        </Typography>
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography variant="h3" color="textPrimary">
          Upload a Document
        </Typography>
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography variant="h3" color="textPrimary">
          Company Documents
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Ex: Employee Handbook
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Documents;
