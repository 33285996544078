import React, { useState, useEffect } from "react";
import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  roles_and_permissions_Data,
  users_HR_Data,
  evaluation_HR_Data,
  groups_HR_Data,
  boarding_HR_Data,
  hiring_HR_Data,
  hierarchy_HR_Data,
  events_HR_Data,
  template_HR_Data,
  locations_HR_Data,
} from "../constants";

import { makeStyles } from "@material-ui/core/styles";
import { ovHrV5Color } from "../../../../../config";


const useStyles = makeStyles((theme) => ({
  hrCheckboxRoot: {
    padding: "30px 20px",
    color: `${ovHrV5Color.main} !important`,
    "&.Mui-checked": {
      color: `${ovHrV5Color.main} !important`,
    },
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
    },
  },
  hrTextField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: `${ovHrV5Color.main} !important`,
      },
    },
  },
  select: {
    "MuiSelect-outlined.MuiSelect-outlined": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    '& .MuiInputBase-input': {
      '&:focus': {
      borderRadius: 6,
      borderColor: `${ovHrV5Color.main} !important`,
    },
    },
    "&:before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:after": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
  },
  icon: {
    fill: ovHrV5Color.main,
  },
  headCell: {
    color: "#ffffff !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px !important",
    padding: "2px !important",
  },
  title: {
    flex: "1 1 100% !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  hrExpansionPanelMain: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummary: {
    backgroundColor: `${ovHrV5Color.main} !important`,
  },
  hrHeading: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "#ffffff !important",
  },
  headingReports: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "black !important",
  },
  hrHeadingIcon: {
    color: "#ffffff !important",
  },
  hrExpansionPanelDetailsSecond: {
    borderLeft: `5px solid  ${ovHrV5Color.main} !important`,
    borderRight: `5px solid  ${ovHrV5Color.main} !important`,
    borderBottom: `5px solid ${ovHrV5Color.main} !important`,
    padding: "20px !important !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  hrExpansionPanel: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummaryThird: {
    backgroundColor: "#f6f7f754 !important",
    padding: "0px 10px !important",
    height: "30px !important",
  },
  hrExpansionPanelDetailsThird: {
    padding: "10px 20px !important",
  },
  ExpansionPanelDetailsThirdCategory: {
    padding: "10px 20px !important",
    height: "20px !important",
  },
  ExpansionPanelDetailsThirdRole: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  ExpansionPanelDetailsThirdBase: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
  },
  hrFormControlLabelSecond: {
    padding: "6px !important",
  },
  hrPageTitle: {
    lineHeight: "40px !important",
    color: "black !important",
  },
  pageHeading: {
    fontSize: "40px !important",
    color: ovHrV5Color.main,
    fontWeight: "700 !important",
  },
  viewLogsBtn: {
    border: `1px solid ${ovHrV5Color.main} !important`,
    borderRadius: "5px !important",
    color: `${ovHrV5Color.main} !important`,
    display: "inline-block !important",
    fontSize: "13px !important",
    height: "100% !important",
    width: "auto !important",
    padding: "8px 15px !important",
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
      textDecoration: "none !important",
    },
  },
}))

const HRContent = ({ onPermissionsUpdate, checkboxesData }) => {
  const styles = useStyles();

  const initializeAccessRights = () => ({
    locations_HR_Data: locations_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    roles_and_permissions_Data: roles_and_permissions_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    users_HR_Data: users_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    evaluation_HR_Data: evaluation_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    groups_HR_Data: groups_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    boarding_HR_Data: boarding_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    hiring_HR_Data: hiring_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    hierarchy_HR_Data: hierarchy_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    events_HR_Data: events_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    template_HR_Data: template_HR_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
  });

  const [accessRights, setAccessRights] = useState(initializeAccessRights());

  useEffect(() => {
    setAccessRights(initializeAccessRights());
  }, [checkboxesData]);

  const handleCheckboxChange = (group, index = null) => {
    setAccessRights((prevState) => {
      if (Array.isArray(prevState[group])) {
        let updatedGroup;

        if (index !== null) {
          updatedGroup = [...prevState[group]];
          updatedGroup[index].checked = !updatedGroup[index].checked;
        } else {
          const allChecked = prevState[group].every((item) => item.checked);
          updatedGroup = prevState[group].map((item) => ({
            ...item,
            checked: !allChecked,
          }));
        }

        const newState = { ...prevState, [group]: updatedGroup };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      } else {
        const newState = { ...prevState, [group]: !prevState[group] };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      }
    });
  };

  const getPermissionsValues = (state) => {
    const permissions = {
      locations_HR_Data_Count: state.locations_HR_Data.filter((item) => item.checked).length,
      roles_and_permissions_Data_Count: state.roles_and_permissions_Data.filter((item) => item.checked).length,
      users_HR_Data_Count: state.users_HR_Data.filter((item) => item.checked).length,
      evaluation_HR_Data_Count: state.evaluation_HR_Data.filter((item) => item.checked).length,
      groups_HR_Data_Count: state.groups_HR_Data.filter((item) => item.checked).length,
      boarding_HR_Data_Count: state.boarding_HR_Data.filter((item) => item.checked).length,
      hiring_HR_Data_Count: state.hiring_HR_Data.filter((item) => item.checked).length,
      hierarchy_HR_Data_Count: state.hierarchy_HR_Data.filter((item) => item.checked).length,
      events_HR_Data_Count: state.events_HR_Data.filter((item) => item.checked).length,
      template_HR_Data_Count: state.template_HR_Data.filter((item) => item.checked).length,
    };

    Object.entries(state).forEach(([groupKey, group]) => {
      if (Array.isArray(group)) {
        group.forEach((item) => {
          permissions[item.Name] = item.checked ? 1 : 0;
        });
      }
    });
    return permissions;
  };

  useEffect(() => {
    onPermissionsUpdate(getPermissionsValues(accessRights));
  }, []);

  const renderAccessRightPanel = (title, groupKey, data = []) => (
    <ExpansionPanel className={styles.hrExpansionPanel}>
      <ExpansionPanelSummary
        className={styles.hrExpansionPanelSummaryThird}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${groupKey}-content`}
        id={`${groupKey}-header`}
      >
        <Checkbox
          color="primary"
          checked={Array.isArray(data) && data.every((item) => item.checked)}
          indeterminate={
            Array.isArray(data) &&
            data.some((item) => item.checked) &&
            !data.every((item) => item.checked)
          }
          onChange={() => handleCheckboxChange(groupKey)}
          className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
        />
        <Typography className={styles.hrPageTitle} variant="h6" color="primary">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      {data.length > 0 && (
        <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsThird}>
          <Grid container>
            {data.map((item, index) => (
              <Grid item md={4} xs={12} key={item.Name}>
                <FormControlLabel
                
                  control={
                    <Checkbox
                      color="primary"
                      checked={item.checked}
                      onChange={() => handleCheckboxChange(groupKey, index)}
                      className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                    />
                  }
                  label={item.Label}
                />
              </Grid>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      )}
    </ExpansionPanel>
  );

  return (
    <Grid item md={12} xs={12}>
      <ExpansionPanel className={styles.hrExpansionPanelMain}>
        <ExpansionPanelSummary
          className={styles.hrExpansionPanelSummary}
          expandIcon={<ExpandMoreIcon className={styles.hrHeadingIcon} />}
          aria-controls="hr-content"
          id="hr-header"
        >
          <Typography className={styles.hrHeading}>HR</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsSecond}>
          <Grid container>
          
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Roles and Permissions",
                "roles_and_permissions_Data",
                accessRights.roles_and_permissions_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Locations", "locations_HR_Data", accessRights.locations_HR_Data)}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Users", "users_HR_Data", accessRights.users_HR_Data)}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Evaluation", "evaluation_HR_Data", accessRights.evaluation_HR_Data)}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Groups", "groups_HR_Data", accessRights.groups_HR_Data)}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Onboarding/Offboarding", "boarding_HR_Data", accessRights.boarding_HR_Data)}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Hiring", "hiring_HR_Data", accessRights.hiring_HR_Data)}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Hierarchy", "hierarchy_HR_Data", accessRights.hierarchy_HR_Data)}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Events", "events_HR_Data", accessRights.events_HR_Data)}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel("Templates", "template_HR_Data", accessRights.template_HR_Data)}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
};

export default HRContent;
