import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import { usersServices } from "../../UsersList/modules/services";
import NewsContent from "./components/News";
import SchedulerContent from "./components/Scheduler";
import HRContent from "./components/HR";
import ELearningContent from "./components/ELearning";
import { makeStyles } from "@material-ui/core/styles";
import { ovHrV5Color } from "../../../../config";
import Cookies from "universal-cookie";
const cookies = new Cookies();
var ovLevel = cookies.get("ovLevel");

const useStyles = makeStyles((theme) => ({
  hrCheckboxRoot: {
    padding: "30px 20px",
    color: `${ovHrV5Color.main} !important`,
    "&.Mui-checked": {
      color: `${ovHrV5Color.main} !important`,
    },
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
    },
  },
  hrTextField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: `${ovHrV5Color.main} !important`,
      },
    },
  },
  select: {
    "MuiSelect-outlined.MuiSelect-outlined": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    '& .MuiInputBase-input': {
      '&:focus': {
        borderRadius: 6,
        borderColor: `${ovHrV5Color.main} !important`,
      },
    },
    "&:before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:after": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
  },
  icon: {
    fill: ovHrV5Color.main,
  },
  headCell: {
    color: "#ffffff !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px !important",
    padding: "2px !important",
  },
  title: {
    flex: "1 1 100% !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  hrExpansionPanelMain: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummary: {
    backgroundColor: `${ovHrV5Color.main} !important`,
  },
  hrHeading: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "#ffffff !important",
  },
  headingReports: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "black !important",
  },
  hrHeadingIcon: {
    color: "#ffffff !important",
  },
  hrExpansionPanelDetailsSecond: {
    borderLeft: `5px solid  ${ovHrV5Color.main} !important`,
    borderRight: `5px solid  ${ovHrV5Color.main} !important`,
    borderBottom: `5px solid ${ovHrV5Color.main} !important`,
    padding: "20px !important !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  hrExpansionPanel: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummaryThird: {
    backgroundColor: "#f6f7f754 !important",
    padding: "0px 10px !important",
    height: "30px !important",
  },
  hrExpansionPanelDetailsThird: {
    padding: "10px 20px !important",
  },
  ExpansionPanelDetailsThirdCategory: {
    padding: "10px 20px !important",
    height: "20px !important",
  },
  ExpansionPanelDetailsThirdRole: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  ExpansionPanelDetailsThirdBase: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
  },
  hrFormControlLabelSecond: {
    padding: "6px !important",
  },
  hrPageTitle: {
    lineHeight: "40px !important",
    color: "black !important",
  },
  pageHeading: {
    fontSize: "40px !important",
    color: ovHrV5Color.main,
    fontWeight: "700 !important",
  },
  viewLogsBtn: {
    border: `1px solid ${ovHrV5Color.main} !important`,
    borderRadius: "5px !important",
    color: `${ovHrV5Color.main} !important`,
    display: "inline-block !important",
    fontSize: "13px !important",
    height: "100% !important",
    width: "auto !important",
    padding: "8px 15px !important",
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
      textDecoration: "none !important",
    },

  },
}));

const RoleFormContent = ({ roleData, isEdit, onRoleDataChange }) => {
  
  const styles = useStyles();
  const [rolesList, setRolesList] = useState([]);
  const [formData, setFormData] = useState(roleData);
console.log(roleData,"roleData")
console.log(formData,"formData")
  useEffect(() => {
    setFormData(roleData || {});
  }, [roleData]);

  // useEffect(() => {
  //   const fetchRoles = async () => {
  //     try {
  //       const response = await usersServices.fetchDropDownRolesData();
  //       if (response.data.success !== 2) {
  //         const formattedRoles = response.data.result.map((item) => ({
  //           value: item._id,
  //           label: item.role_name,
  //         }));
  //         setRolesList(formattedRoles);
  //         if (!isEdit && formattedRoles.length > 0) {
  //           const defaultRole = formattedRoles[0].value;
  //           setFormData((prevData) => ({
  //             ...prevData,
  //             role_parentId: formData.parent_role_name,
  //           }));
  //           onRoleDataChange({ ...formData, role_parentId:formData.parent_role_name });
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching roles for dropdown:", error);
  //     }
  //   };
  //   fetchRoles();
  // }, [isEdit]);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   const updatedData = { ...formData, [name]: value };
  //   setFormData(updatedData);
  //   onRoleDataChange(updatedData);
  // };



    useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await usersServices.fetchDropDownRolesData();
        if (response.data.success !== 2) {
          const formattedRoles = response.data.result.map((item) => ({
            value: item._id,
            label: item.role_name,
          }));
          setRolesList(formattedRoles);

          if (!isEdit) {
            const defaultRole = formattedRoles[0];
            setFormData((prevData) => ({
              ...prevData,
              role_parentId: prevData.role_parentId || defaultRole.value,
              parent_role_name: prevData.parent_role_name || defaultRole.label,
            }));
            onRoleDataChange({
              ...formData,
              role_parentId: formData.role_parentId || defaultRole.value,
              parent_role_name: formData.parent_role_name || defaultRole.label,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching roles for dropdown:", error);
      }
    };
    fetchRoles();
  }, [isEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...formData, [name]: value };

    // Update parent_role_name dynamically when role_parentId changes
    if (name === "role_parentId") {
      const selectedRole = rolesList.find((role) => role.value === value);
      updatedData = {
        ...updatedData,
        parent_role_name: selectedRole ? selectedRole.label : "",
      };
    }

    setFormData(updatedData);
    onRoleDataChange(updatedData);
  };
  const handlePermissionsUpdate = (updatedPermissions) => {
    const updatedData = { ...formData, ...updatedPermissions };
    setFormData(updatedData);
    onRoleDataChange(updatedData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="role_name"
          label="Role Name"
          value={formData.role_name}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          placeholder={isEdit ? "Edit Role Name" : "Add Role Name"}
          className={styles.hrTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="role_description"
          label="Role Description"
          value={formData.role_description}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          placeholder={
            isEdit ? "Edit Role Description" : "Add Role Description"
          }
          className={styles.hrTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Reports To</Typography>
        {
          formData.role_parentId ?
            ovLevel == formData.level ?
              <Box
                fullWidth
                variant="outlined"
              >
                {/* <Typography>{formData?.parent_role_name}</Typography> */}
                <Typography>{formData.parent_role_name || "Default Role"}</Typography>

              </Box>
              :
              <Select
                name="role_parentId"
                value={formData.role_parentId || ""}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                input={<OutlinedInput />}
              >
                {rolesList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            :
            <></>
        }
      </Grid>
      <ELearningContent
        checkboxesData={formData}
        onPermissionsUpdate={handlePermissionsUpdate}
      />
      <NewsContent
        checkboxesData={formData}
        onPermissionsUpdate={handlePermissionsUpdate}
      />
      <SchedulerContent
        checkboxesData={formData}
        onPermissionsUpdate={handlePermissionsUpdate}
      />
      
      <HRContent
        checkboxesData={formData}
        onPermissionsUpdate={handlePermissionsUpdate}
      />
    </Grid>
  );
};

export default RoleFormContent;
