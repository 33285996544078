import React, { useState, useEffect, useRef, useMemo } from "react";
// import { Jodit } from "jodit";
import JoditReact from "jodit-react";
import axios from "axios";
import { HEADER_TOKEN, MY_TOKEN } from "../config";

const JoditEditor = (props) => {
  // const [editorContent, setEditorContent] = useState("");

  const { handleEditor } = props;
  const editor = useRef(null);

  const editorConfig = {
    filebrowser: {
      ajax: {
        url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/upload`,
      },
    },
    uploader: {
      url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/upload`,
      format: "json",
      headers: {
        Authorization: MY_TOKEN,
      },
      prepareData: function (data) {
        const file = data.get("files[0]");
        data.delete("files[0]");
        data.append("myfile", file);
        data.append("product_name", "testing");
      },
      isSuccess: function (response) {
        return !response.error;
      },
      getMessage: function (response) {
        return response.message || "Upload successful";
      },
      process: function (response) {
        console.log("Raw Response from Server:", response);
        return {
          url: response.result,
          error: response.error || "",
          msg: response.message,
        };
      },
      defaultHandlerSuccess: function (data, response) {
        const imageUrl = data.url;
        if (imageUrl && this.jodit) {
          insertImage(this.jodit, imageUrl);
        } else {
          console.error("Editor instance or image URL is missing.");
        }
      },
      error: function (e) {
        this.message.message(e.getMessage(), "error", 4000);
      },
    },
    events: {
      paste: function (event) {
        const editor = this;
        const clipboardData = event.clipboardData || window.Clipboard;

        if (clipboardData && clipboardData.items.length > 0) {
          handlePasteImage(editor, clipboardData);
        }
      },
    },
    width: "100%",
    maxWidth: "100%",
    height: "auto",
    minHeight: "400px",
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "large",
    // toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    placeholder: "Write something awesome ...",
    defaultStyle: {
      textAlign: "left",
    },
    style: {
      textAlign: "left",
    },
    // beautyHTML: true,
    controls: {
      font: {
        command: "fontname",
        data: {
          cssRule: "font-family",
          //normalize: "function(e){return e.toLowerCase().replace(/['\"]+/g,\"\").replace(/[^a-z0-9]+/g,\",\")}"
        },
        list: {
          "": "Default",
          "Quicksand,sans-serif": "Quicksand",
          "Helvetica,sans-serif": "Helvetica",
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "'Times New Roman',Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana",
          "Lucida Console": "Lucida Console",
          "Lucida Handwriting": "Lucida Handwriting",
          Papyrus: "Papyrus",
          Monaco: "Monaco",
          Roboto: "Roboto",
          "Brush Script MT": "Brush Script MT",
          "Courier New": "Courier New",
          monospace: "monospace",
        },
        exec: 'function(e,t,n){var r=n.control;return a.memorizeExec(e,t,{control:r},(function(t){var n;return"fontsize"===(null===(n=r.command)||void 0===n?void 0:n.toLowerCase())?""+t+e.o.defaultFontSizePoints:t}))}',
        childTemplate: function (e, t, n) {
          return `<span style="font-family:${t}!important;">${n}</span>`;
        },

        tooltip: "Font family",
        isChildActive:
          'function(e,t){var n,r,o=e.s.current(),s=(null===(n=t.data)||void 0===n?void 0:n.cssRule)||"font-size",l=(null===(r=t.data)||void 0===r?void 0:r.normalize)||function(t){return/pt$/i.test(t)&&"pt"===e.o.defaultFontSizePoints?t.replace(/pt$/i,""):t};if(o){var u=i.Dom.closest(o,(function(t){return i.Dom.isBlock(t,e.ew)||t&&i.Dom.isElement(t)}),e.editor)||e.editor,c=a.css(u,s);return Boolean(c&&t.args&&l(t.args[0].toString())===l(c.toString()))}return!1}',
      },
      image: {
        exec: async (editor) => {
          await imageUpload(editor);
        },
        execOnPaste: async (editor) => {
          await handlePasteImage(editor);
        },
      },
    },
  };

  const insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  const imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }

      let data = new FormData();
      data.append("myfile", imageFile);
      data.append("product_name", "testing");

      // const imageInfo = await FileUpload(imageFile);
      const imageInfo = await axios.post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/upload`,
        data,
        HEADER_TOKEN
      );

      insertImage(editor, imageInfo.data.result);
    };
  };

  const handlePasteImage = async (editor) => {
    const clipboardData = navigator.clipboard || window.clipboardData;

    if (clipboardData && clipboardData.items) {
      const item = clipboardData.items.find(
        (item) => item.type === "image/png" || item.type === "image/jpeg"
      );

      if (item) {
        const blob = await new Promise((resolve) => {
          if (item.getAsFile) {
            item.getAsFile(resolve);
          } else {
            resolve(null);
          }
        });

        if (blob) {
          try {
            const formData = new FormData();
            formData.append("image", blob);

            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/upload`,
              formData,
              HEADER_TOKEN
            );

            insertImage(editor, response.data.result);
          } catch (error) {
            console.error("Error pasting image:", error);
          }
        } else {
          console.error("No blob found from pasted image.");
        }
      } else {
        console.error("No image found in clipboard.");
      }
    } else {
      console.error("Clipboard data not accessible.");
    }
  };

  return useMemo(
    () => (
      <div style={{ maxWidth: editorConfig.width, margin: "0 auto" }}>
        <JoditReact
          value={props.value}
          config={editorConfig}
          ref={editor}
          onChange={(newContent) => handleEditor(newContent)}
          onBlur={(value, event) => console.log(event)}
        />
      </div>
    ),
    []
  );
};

export default JoditEditor;
