import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { documentStyles } from "../Styles";

const AutoSchedule = () => {
    const classes = documentStyles();

  return (
    <Grid container spacing={2} className={classes.root} sx={{ paddingTop: "1rem" }}>
      <Grid item md={12} xs={12}>
        <Typography variant="h3" color="textPrimary">
          Auto-Schedule Rules
        </Typography>
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography variant="body1" color="textPrimary">
          We use the settings here to distribute shifts via the auto scheduling
          feature, as well as employee’s personal time off and availability.
          Enable auto scheduling here.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AutoSchedule;
