import { makeStyles } from "@mui/styles";
import { ovHrV5Color } from "../../../../config";

export const EditUserStyles = makeStyles({
  
});

export const userDetailsStyles = makeStyles({
  hrUserDetailsLabel: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: `${ovHrV5Color.main} !important`,
  },

  root: {
    padding: "1rem !important",
  },

  hrUserDetailsCardText: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: "black !important",
    display: "inline",
    float: "left",
  },

  hrUserDetailsCardTextBold: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: "grey !important",
    display: "inline",
    float: "right",
  },
});

export const locationStyles = makeStyles({
  hrLocationContainer: {
    padding: "1rem !important",
  },

  root: {
    color: "#007E63 !important",
    "&.Mui-checked": {
      color: "#007E63 !important",
    },
    "&:hover": {
      color: "#007E63 !important",
    },
  },

  FormControlLabelSecond: {
    padding: "6px !important",
  },

  switch: {
    color: "#007E63 !important",
  },

  locationField: {
    backgroundColor: "white !important",
  },

  hrLocationLabel: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: `${ovHrV5Color.main} !important`,
    marginBottom: "0.5rem !important",
  },
});

export const documentStyles = makeStyles({
  root: {
    padding: "1rem !important",
  },
});

export const timeOffStyles = makeStyles({
  root: {
    padding: "8px !important",
  },
  headCell: {
    padding: "0.5rem 0rem !important",
    color: "#ffffff !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
  },
  rootToolbar: {
    minHeight: "58px !important",
    padding: "8px !important",
  },
  title: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    lineHeight: "27px !important",
    fontSize: "18px !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0) !important",
    height: 1,
    margin: -1,
    overflow: "hidden !important",
    padding: 0,
    position: "absolute !important",
    top: 20,
    width: 1,
  },
  subtitle: {
    marginBottom: "3px !important",
    color: "#7C7C7C !important",
  },
  userName: {
    maxWidth: "250px !important",
    lineHeight: 3,
    display: "-webkit-box !important",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
  },
});

export const notesStyles = makeStyles({
  root: {
    padding: "1.5rem 1rem !important",
  },
  switch: {
    color: "#B71C1C !important",
  },
  avatar: {
    marginRight: "8px !important",
    float: "left !important",
    textTransform: "uppercase !important",
    height: "30px !important",
    width: "30px !important",
    fontWeight: "800 !important",
    fontSize: "13px !important",
    backgroundColor: ovHrV5Color.main,
    color: "white !important",
  },
  locationField: {
    backgroundColor: "white !important",
  },

  hrNotesDataContainer: {
    paddingTop: "1rem !important",
  },

  hrNotesDataText: {
    textAlign: "left !important", 
    fontWeight: "bold !important"
  },

  hrNotesDataBtn: {
    textAlign: "right !important", 
    cursor: "pointer !important",
  },

  hrNotesDataEditIcon: {
    textAlign: "right", 
    cursor: "pointer"
  },

  hrNotesDataDate: {
    color: "grey !important", 
    paddingLeft: "35px !important", 
    fontSize: "14px !important",
  },

  hrNotesAddNotesBtn: {
    padding: "5px !important", 
    backgroundColor: "#007E63 !important", 
    display: "flex !important", 
    float: "right !important", 
    color: "white !important",
  },

  hrNotesSaveNotesBtn:{
    padding: "5px !important", 
    backgroundColor: "#007E63 !important", 
    display: "flex !important", 
    float: "right !important", 
    color: "white !important",
  },

  hrNotesBtnContainer:{
    paddingTop: "1rem !important", 
  },

  hrNotesTextArea: {
    paddingTop: "1rem !important", 
    marginLeft: "1px !important",
  }
});

export const notificationStyles = makeStyles({
  

});
