import React from "react";
import {
  Typography,
  Grid,
  Divider,
  FormControl,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { ovHrV5Color } from "../../../../../config";
import { managerNotificationsList, notificationsList } from "../constants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem !important",
    color: ovHrV5Color.main,
    "&.Mui-checked": {
      color: ovHrV5Color.main,
    },
    "&:hover": {
      color: ovHrV5Color.main,
    },
  },
  headText: {
    color: "#313a43 !important",
    fontWeight: "600 !important",
    fontSize: "18px !important",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007E63 !important",
      },
    },
  },
  bgColor: {
    border: "1px solid #f6f7f7 !important",
    margin: "0px 10px !important",
    padding: "5px !important",
  },
  disabledButton: {
    backgroundColor: "#E5E6E7 !important",
    border: "1px solid #96A6BC !important",
    color: "#000000 !important",
    textTransform: "none !important",
    height: "30px !important",
    width: "100px !important",
    fontWeight: "900 !important",
  },
  selectedButton: {
    border: "1px solid #219653 !important",
    backgroundColor: "#219653 !important",
    textTransform: "none !important",
    color: "white !important",
    height: "30px !important",
    width: "100px !important",
    fontWeight: "900 !important",
    "&:hover": {
      backgroundColor: "#0e6734 !important",
      border: `1px solid ${ovHrV5Color.main} !important`,
    },
  },
  normalButton: {
    backgroundColor: "white !important",
    textTransform: "none !important",
    borderColor: "#007E63 !important",
    color: "#007E63 !important",
    height: "30px !important",
    width: "100px !important",
    fontWeight: "900 !important",
    "&:hover": {
      borderColor: "#007E63 !important",
      color: "#007E63 !important",
    },
  },

  hrNotifyHeadText: {
    marginTop: "5px !important",
    padding: "12px !important",
  },

  hrNotifyTitle: {
    padding: "5px, 0px !important",
    color: "#313a43",
  },

  hrNotifySecondContainer: {
    marginTop: "25px !important",
    padding: "12px !important",
  },
}));

const Notifications = ({
  notificationsData,
  permissionsData,
  handleChangeNotifications,
  handleChangeData,
  handleChangeText,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item md={9} xs={12}>
        <Typography gutterBottom variant="h4">
          If you haven’t already, get the Onevision Mobile App on your phone.
        </Typography>
        <Typography gutterBottom variant="body1">
          Available on both iOS and Android
        </Typography>
      </Grid>

      <Grid item md={9} xs={12} className={classes.hrNotifyHeadText}>
        <Typography className={classes.headText} gutterBottom>
          Shifts & Schedule Notifications
        </Typography>
      </Grid>

      <Grid item md={3} xs={false} className={classes.hrNotifyHeadText}>
        <Typography className={classes.headText} gutterBottom>
          Notification Type
        </Typography>
      </Grid>

      <Grid item md={12} xs={12} style={{ padding: "0px 12px" }}>
        <Divider style={{ backgroundColor: ovHrV5Color.main }} />
      </Grid>

      {notificationsList.map((item, index) => {
        const isModulo = index % 2;
        return (
          <Grid
            container
            spacing={3}
            className={classes.bgColor}
            style={{
              backgroundColor: isModulo ? "#ffffff" : "#f6f7f7",
            }}
            key={index}
          >
            <Grid item md={9} xs={12}>
              <Typography className={classes.hrNotifyTitle} variant="h5">
                {item.title}
              </Typography>
            </Grid>

            <Grid item md={3} xs={12}>
              <Grid container justify="space-between">
                {item.buttons.map((values, idx) => (
                  <Button
                    key={idx}
                    onClick={() => handleChangeNotifications(values.name)}
                    className={
                      notificationsData[values.name]
                        ? classes.selectedButton
                        : classes.normalButton
                    }
                    variant="outlined"
                    disabled={values.isDisabled}
                    classes={{
                      disabled: values.isDisabled
                        ? classes.disabledButton
                        : null,
                    }}
                    startIcon={
                      notificationsData[values.name] ? <CheckIcon /> : null
                    }
                  >
                    {values.isEmail ? "Email" : "Push"}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      {permissionsData.receive_manager_alert && (
        <>
          <Grid className={classes.hrNotifySecondContainer} item md={9} xs={12}>
            <Typography className={classes.headText} gutterBottom>
              Manager Alerts
            </Typography>
            <Typography variant="h5" component="div">
              <FormControlLabel
                style={{ marginTop: "7px" }}
                control={
                  <Checkbox
                    className={`${classes.root} ${classes.FormControlLabelSecond}`}
                    name="alert_notify_manager_emp_not_clock_in"
                    checked={
                      notificationsData.alert_notify_manager_emp_not_clock_in
                        ? true
                        : false
                    }
                    onChange={handleChangeData}
                  />
                }
              />
              Notify managers when an employee does not clock in after
              <FormControl
                className={classes.textField}
                sx={{
                  "& fieldset": { borderColor: "red !important" },
                  m: 1,
                  width: "25ch",
                }}
              >
                <OutlinedInput
                  className={classes.textField}
                  style={{
                    height: 20,
                    width: 50,
                    marginTop: 18,
                    marginLeft: 10,
                    marginRight: 10,
                    color: ovHrV5Color.main,
                  }}
                  type="text"
                  value={
                    notificationsData.alert_notify_manager_emp_not_clock_in_min
                  }
                  onChange={handleChangeText}
                  name="alert_notify_manager_emp_not_clock_in_min"
                />
              </FormControl>
              mins.
            </Typography>
          </Grid>

          <Grid
            style={{ marginTop: "25px", padding: "12px" }}
            item
            md={3}
            xs={false}
          >
            <Typography className={classes.headText} gutterBottom>
              Notification Type
            </Typography>
          </Grid>

          <Grid item md={12} xs={12} style={{ padding: "0px 12px" }}>
            <Divider style={{ backgroundColor: ovHrV5Color.main }} />
          </Grid>

          {managerNotificationsList.map((item, index) => {
            const isModulo = index % 2;
            return (
              <Grid
                container
                spacing={3}
                className={classes.bgColor}
                style={{
                  backgroundColor: isModulo ? "#ffffff" : "#f6f7f7",
                }}
                key={index}
              >
                <Grid item md={9} xs={12}>
                  <Typography className={classes.hrNotifyTitle} variant="h5">
                    {item.title}
                  </Typography>
                </Grid>

                <Grid item md={3} xs={12}>
                  <Grid container justify="space-between">
                    {item.buttons.map((values, idx) => (
                      <Button
                        key={idx}
                        onClick={() => handleChangeNotifications(values.name)}
                        className={
                          notificationsData[values.name]
                            ? classes.selectedButton
                            : classes.normalButton
                        }
                        variant="outlined"
                        disabled={values.isDisabled}
                        classes={{
                          disabled: values.isDisabled
                            ? classes.disabledButton
                            : null,
                        }}
                        startIcon={
                          notificationsData[values.name] ? <CheckIcon /> : null
                        }
                      >
                        {values.isEmail ? "Email" : "Push"}
                      </Button>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default Notifications;
